<template>
  <div class="course-header bg-inverted-darker">
    <div class="course-header__inner max">
      <p
        class="course-header__type"
        :class="{ 'course-header__tag': !isMajor }"
        data-test="course-header-type"
        v-html="studyLevel"
      />
      <!-- eslint-disable-line vue/no-v-html -->
      <h1
        id="page-header"
        class="course-header__title"
        data-test="course-header-title"
      >
        {{ name }}
      </h1>
      <ul v-if="statistics.length" class="course-header__statistics list-reset">
        <li
          v-for="(stat, index) in statistics"
          :key="`stat-${index}`"
          class="course-header__stat"
        >
          <SvgIcon class="course-header__stat-icon" name="arrow-right" />
          <span
            v-if="stat && stat.lead"
            data-test="course-header-stat-number"
            class="course-header__stat-number"
          >
            {{ stat.lead | truncate(4) }}
          </span>
          <a
            :href="
              stat.link ? stat.link : $t('component.headerbanner.stats.url')
            "
            class="course-header__stat-link"
            :aria-label="
              stat.lead + ' ' + stat.title + '(link opens in new window)'
            "
            data-test="course-header-stat-link"
            target="_blank"
          >
            {{ stat.title | truncate(50) }}
          </a>
        </li>
      </ul>
      <div data-test="course-header-btns" class="course-header__btns">
        <ButtonIcon
          v-for="(button, index) in buttons"
          :key="index"
          data-test="course-header-btn"
          :class="index === 1 ? 'btn--secondary' : ''"
          :icon="button.icon"
          :href="button.link"
        >
          {{ button.text }}
        </ButtonIcon>
      </div>
      <CtaButtonBanner
        v-if="bannerCta.active_flag && isDomestic"
        :label="bannerCta.label"
        :link="bannerCta.link"
        :left-icon="bannerCta.left_icon"
        :right-icon="bannerCta.right_icon"
      />
      <div
        v-if="alert.active_flag"
        data-test="course-header-alert"
        class="course-header__alert shim-mt05"
      >
        <SvgIcon class="course-header__alert-icon" name="info" />
        <span class="course-header__alert-text" v-html="alert.content" />
        <!-- eslint-disable-line vue/no-v-html -->
      </div>
      <ul
        v-if="codes.length && !isMajor"
        class="course-header__codes list-reset"
        data-test="course-header-codes"
      >
        <li
          v-for="(code, index) in codes"
          :key="`code-${index}`"
          class="course-header__code"
        >
          <span v-if="code.code && code.code.trim().length > 0">
            {{ code.title }}:
          </span>
          <span class="text-bold">{{ code.code }}</span>
        </li>
      </ul>
    </div>

    <div
      data-test="course-header-img"
      :style="{ backgroundImage: `url(${bgImg})` }"
      class="course-header__img bg-inverted-darker"
      :class="{ 'course-header__img--default': bgImgDefault }"
    />
  </div>
</template>

<script>
import { ButtonIcon, SvgIcon } from '@unimelb/pattern-lib-vue';
import imgPlaceholder from '~/assets/placeholder-new.jpg';
import CtaButtonBanner from '~/components/CtaButtonBanner.vue';

export default {
  components: {
    ButtonIcon,
    SvgIcon,
    CtaButtonBanner,
  },
  props: {
    type: {
      type: String,
      default: 'Undergraduate',
    },
    name: {
      type: String,
      default: 'Unknown',
    },
    statistics: {
      type: Array,
      default: () => [],
    },
    codes: {
      type: Array,
      default: () => [],
    },
    image: {
      type: String,
      default: '',
    },
    buttons: {
      type: Array,
      default: () => [],
    },
    bannerCta: {
      type: Object,
      default: () => ({
        active_flag: false,
      }),
    },
    alert: {
      type: [Object, String],
      default: () => ({
        active_flag: false,
        content: '',
      }),
    },
  },
  computed: {
    isMajor() {
      const offeringType = [
        'major',
        'minor',
        'specialisation',
        'honours specialisation',
      ];
      return offeringType.includes(this.type);
    },
    isDomestic() {
      return (
        this.$store.getters['offerings/residency'].toLowerCase() === 'domestic'
      );
    },
    studyLevel() {
      if (this.isMajor) {
        return this.type;
      }
      if (this.type === 'Undergraduate') {
        return '<a href="https://study.unimelb.edu.au/study-with-us/undergraduate-courses">Undergraduate</a>';
      }
      if (this.type === 'Graduate') {
        return '<a href="https://study.unimelb.edu.au/study-with-us/graduate-courses">Graduate Coursework</a>';
      }
      if (this.type === 'Research') {
        return '<a href="https://study.unimelb.edu.au/study-with-us/graduate-research">Graduate Research</a>';
      }
      if (this.type === 'Short course') {
        return '<a href="https://study.unimelb.edu.au/study-with-us/professional-development">Short Course</a>';
      }
      if (this.type === 'Micro-credential') {
        return '<a href="https://study.unimelb.edu.au/study-with-us/professional-development">Microcredential</a>';
      }
      return this.type;
    },
    bgImg() {
      return this.image ? this.image : imgPlaceholder;
    },
    bgImgDefault() {
      return !this.image;
    },
  },
};
</script>

<style lang="postcss">
.course-header {
  position: relative;
  height: auto;
  display: grid;
  grid-template-rows: 1fr auto;
  grid-template-columns: auto;

  @media (--bp-x-desktop) {
    display: flex;
    flex-direction: column-reverse;
  }

  &__btns {
    width: 100%;
    margin-bottom: 1rem;

    @media (--bp-desktop) {
      display: flex;
    }
  }

  &__inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-block: var(--sp-layout);
    width: 100%;

    @media (--bp-desktop) {
      padding-block: 4rem 2rem;
    }
    @media (--bp-wide) {
      padding-block: 6rem 2rem;
      padding-right: 22rem;
      margin-left: auto;
    }
  }

  &__title {
    z-index: var(--z-default);
    color: var(--col-text-light);
    text-align: left;
  }

  &__statistics {
    display: flex;
    flex-direction: column;
    margin-bottom: var(--sp-2);
    z-index: var(--z-default);
    gap: 0.5rem;

    @media (--bp-desktop) {
      flex-direction: row;
      width: auto;
      gap: 1.5rem;
    }
  }

  &__stat-icon {
    width: 1.5rem;
    height: 1.5rem;
    color: var(--col-btn-primary-bg);
  }

  &__stat {
    font-weight: var(--fw-bold);
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 0;
    gap: 0.3rem;

    &-link {
      font-weight: var(--fw-semibold);
      color: var(--col-link-light) !important;
      text-decoration: none;
      align-self: start;

      &:hover {
        text-decoration: underline;
      }
    }

    .course-header__stat-icon,
    .course-header__stat-number {
      align-self: start;
    }
  }

  &__type {
    @mixin typography-level-7;
    @mixin typography-uppercase;

    padding: 0.3rem;
  }

  &__tag {
    border: 1px solid var(--col-sage-dark-50);

    a {
      font-weight: var(--fw-semibold);
      text-decoration: none;
    }
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  &__img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 30%;
    height: 100%;
    padding: 0;
    background-position: right;
    background-size: cover;
    display: none;

    @media (--bp-wide) {
      display: block;
    }
  }

  &__img--default {
    background-position: center;
  }

  &__codes {
    display: flex;
    flex-direction: column;
    margin-top: 5rem;
    margin-bottom: 0;

    @media (--bp-x-tablet) {
      border: 0;
      display: flex;
      flex-direction: row;
      width: 100%;
      gap: 1.5rem;
    }

    @media (--bp-max-tablet) {
      margin-top: 0;
    }
  }

  &__code {
    @mixin typography-level-7;

    margin-bottom: 0;
  }

  &__alert {
    display: flex;
    background: var(--col-bg-light);
    color: var(--col-text-primary);
    padding: 1rem 0.468rem;
    margin-bottom: 2rem;

    @media (--bp-tablet) {
      padding: 1rem;
    }

    &-icon {
      position: absolute;
      width: 1.5rem;
      height: 1.5rem;
      margin-top: -0.1rem;
    }

    &-text {
      @mixin typography-level-7;

      display: inline-block;
      padding-left: 1.8rem;
      vertical-align: top;

      a {
        font-weight: var(--fw-regular);
        color: var(--col-link-primary) !important;
      }

      strong {
        font-weight: var(--fw-semibold);
      }
    }
  }
}
</style>
